export const listingPageAnchors = {
  OVERVIEW: 'overview',
  DETAILS: 'vehicle-details',
  AMENITIES: 'amenities',
  ADDONS: 'enhance-your-trip',
  REVIEWS: 'reviews',
  LOCATION: 'delivery',
  RULES: 'rules',
  FAQ: 'faq',
  TOWABLE_FEATURES: 'towable-features',
  LOCATION_HEADER: 'location-header',
  CAMPGROUNDS_BOOK_A_SITE: 'book-a-site',
  VACATION_PACKAGES_BOOK_AN_RV_SUITE: 'book-an-rv-suite',
};
