import React from 'react';
import { useIntl } from 'react-intl';

import AspectRatio from '@/components/common/AspectRatio';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { IOdnMedia } from '@/services/types/search/rentals/id';
import { getYoutubeVideoId } from '@/utility/youtubeParser';

import css from './GalleryItem.module.css';

interface IProps {
  index: number;
  image: string;
  description?: string;
  isVideo?: boolean;
  odnMedia?: IOdnMedia;
}

const GalleryItem: React.FC<IProps> = ({ image, index, description, isVideo, odnMedia }) => {
  const intl = useIntl();

  return (
    <>
      {odnMedia && odnMedia.is360Tour && (
        <div className="flex justify-center">
          <iframe
            title="kuula"
            width="70%"
            height="640"
            frameBorder="0"
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowFullScreen
            scrolling="no"
            src={`${odnMedia.url}?fs=0&vr=1&zoom=1&alpha=0.75&hideinst=1&chromeless=1&logo=false"`}
          />
        </div>
      )}

      {!odnMedia && !isVideo && (
        <ResponsiveImage
          progressive
          priority
          className={css.lightBoxImage}
          sourceSizes={['landscape1440']}
          cropMode="fit"
          src={image}
          alt={intl.formatMessage(
            { defaultMessage: 'Listing photo {photoPosition}', id: 'qMitFI' },
            { photoPosition: index + 1 },
          )}
        />
      )}

      {(isVideo || (odnMedia && odnMedia.isVideo)) && (
        <AspectRatio
          ratio="16:9"
          hasImageHover={false}
          className={`${css.iframeHolder} w-full lg:h-full`}>
          <iframe
            title={description}
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${getYoutubeVideoId(
              odnMedia?.url || image,
            )}?enablejsapi=1&output=embed`}
            frameBorder={0}
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </AspectRatio>
      )}
    </>
  );
};

export default GalleryItem;
