import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ESearchFilters } from '@/constants/searchFilters';
import useQueryParams from '@/hooks/useQueryParams';
import { applySearchFilter } from '@/redux/modules/search';
import { EDeliveryOption } from '@/services/types/core/delivery.types';

import { trackRenterSearched } from '../guided-search-2.0/analytics';
import { EGuidedSearchComponentType, TGuidedSearchComponent } from './types';
import { useGuidedSearchComponents } from './useGuidedSearchComponents';

const singleSelectionComponents = [EGuidedSearchComponentType.ButtonGroupRadio];

type TGuidedSearchContext = {
  reset: () => void;
  goToPrevStep?: () => void;
  goToNextStep?: () => void;
  applySelection: () => void;
  currentStep: number;
  numberOfSteps: number;
  currentStepComponent?: TGuidedSearchComponent;
  currentStepSelection?: Record<string, string | string[] | undefined>;
  updateCurrentStepSelection: (fieldId: string, value?: string | string[]) => void;
  hasSelectedData?: boolean;
};

const GuidedSearchContext = createContext<TGuidedSearchContext>({
  reset: noop,
  applySelection: noop,
  currentStep: 1,
  numberOfSteps: 1,
  updateCurrentStepSelection: noop,
});

export const useGuidedSearchContext = () => useContext(GuidedSearchContext);

type TStepsSelection = Record<string, Record<string, string | string[] | undefined>>;

export const GuidedSearchProvider: React.FC<
  React.PropsWithChildren<{ onApplySelection?: () => void }>
> = ({ onApplySelection, children }) => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  const { data: guidedSearchData } = useGuidedSearchComponents();

  // Filter out the components that contains non recognized component types
  const guidedSearchStepsData = useMemo(() => {
    return guidedSearchData?.data_file.components.reduce((acc, component) => {
      const fields = component.fields.filter(field =>
        Object.values(EGuidedSearchComponentType).includes(field.component_type),
      );

      if (fields.length) {
        return [...acc, { ...component, fields }];
      }

      return acc;
    }, [] as TGuidedSearchComponent[]);
  }, [guidedSearchData]);

  const numberOfSteps = guidedSearchStepsData?.length || 0;

  const [currentStep, setCurrentStep] = useState(1);
  const [stepsSelection, setStepsSelection] = useState<TStepsSelection>({});

  useEffect(() => {
    if (window && window.sessionStorage) {
      const savedState = window.sessionStorage.getItem('guidedSearch1State');

      if (savedState) {
        setStepsSelection(JSON.parse(savedState));
      }
    }
  }, []);

  const reset = () => {
    setCurrentStep(1);
  };

  const goToPrevStep =
    currentStep > 1
      ? () => {
          setCurrentStep(currentStep - 1);
        }
      : undefined;

  const goToNextStep =
    currentStep < numberOfSteps
      ? () => {
          setCurrentStep(currentStep + 1);
        }
      : undefined;

  const applySelection = async (currentStepsSelection?: TStepsSelection) => {
    const selection = currentStepsSelection || stepsSelection;

    if (!guidedSearchStepsData || isEmpty(selection)) return;

    const params: Record<string, string> = {};

    // Build the query params based on the selected options
    guidedSearchStepsData.forEach(step => {
      if (!selection[step.id]) return;

      step.fields.forEach(field => {
        if (!selection[step.id]?.[field.id]) return;

        if (field.component_type === EGuidedSearchComponentType.ButtonGroupRadio) {
          const selectedOption = field.properties.rows.find(
            row => row.id === selection[step.id]?.[field.id],
          );

          if (selectedOption) {
            selectedOption.query_params.forEach(param => {
              params[param.key] = params[param.key]
                ? `${params[param.key]},${param.value}`
                : param.value;
            });
          }
        }
      });
    });

    if (params['delivery']) {
      params['deliveryStationary'] = EDeliveryOption.MOVING;
    }

    trackRenterSearched(params, {
      source: 'guided_search_1',
    });

    dispatch(
      applySearchFilter(
        {
          // Persist location & date selection
          ...pick(queryParams, [
            ESearchFilters.ADDRESS,
            ESearchFilters.BOUNDS_NE,
            ESearchFilters.BOUNDS_SW,
            ESearchFilters.DATE_FROM,
            ESearchFilters.DATE_TO,
          ]),
          ...params,
        },

        false,
        false,
        false,
        undefined,
        true,
      ),
    );

    onApplySelection?.();
  };

  const currentStepComponent = guidedSearchStepsData?.[currentStep - 1];

  const moveToNextStepAfterSelection = useMemo(() => {
    if (!currentStepComponent) return false;

    return (
      currentStepComponent.fields.length === 1 &&
      currentStepComponent.fields.every(field =>
        singleSelectionComponents.includes(field.component_type),
      )
    );
  }, [currentStepComponent]);

  const currentStepSelection = stepsSelection[currentStepComponent?.id || ''];
  const updateCurrentStepSelection = (fieldId: string, value?: string | string[]) => {
    if (!currentStepComponent) return;

    const nextStepsSelection: TStepsSelection = {
      ...stepsSelection,
      [currentStepComponent.id]: {
        ...stepsSelection[currentStepComponent.id],
        [fieldId]: value,
      },
    };

    setStepsSelection(nextStepsSelection);

    if (window && window.sessionStorage) {
      window.sessionStorage.setItem('guidedSearch1State', JSON.stringify(nextStepsSelection));
    }

    if (moveToNextStepAfterSelection) {
      (goToNextStep || applySelection)(nextStepsSelection);
    }
  };

  const hasSelectedData = !!(stepsSelection && Object.keys(stepsSelection).length);

  if (!guidedSearchStepsData?.length) return null;

  return (
    <GuidedSearchContext.Provider
      value={{
        reset,
        goToPrevStep,
        goToNextStep,
        applySelection,
        currentStep,
        numberOfSteps,
        currentStepComponent,
        currentStepSelection,
        updateCurrentStepSelection,
        hasSelectedData,
      }}>
      {children}
    </GuidedSearchContext.Provider>
  );
};
